/* PREVENT OVERSCROLL & BOUNCE*/
html,
body {
  position: fixed;
  overflow: hidden;
}

/*  PREVENT USER SELECT */
html {
  user-select: none;
	-webkit-user-select: none;
	-webkit-touch-callout: inherit;
}


/* Allows separateRender to overlap main container*/
#root {
	z-index: 1;
	position: relative;
	display: flex;
	flex: 1;
	width: 100vw;
	height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.animation-fade-in-out {
	animation: fade-in-out 2s infinite;
}

@keyframes fade-in-out {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

img {
	max-width: 100%;
	max-height: 100%;
	-webkit-touch-callout:none;
}


.animation-fade-in {
	opacity: 1;
	animation: animation-fade-in 350ms normal linear;
}

@keyframes animation-fade-in  {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
/*TRADE RESULT ANIMATION*/
.trade-result-win {
	animation: trade-result-win-animation 1.5s normal ease-out;
}

.trade-result-loss {
	animation: trade-result-loss-animation 1.5s normal ease-out;
}

@keyframes trade-result-win-animation {
	0% {
		opacity: 1;
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		transform: translateY(-100%);
	}
}

@keyframes trade-result-loss-animation {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translateY(100%);
	}

}
