.button {
	margin: 0;
	border: 0;
	
	display: flex;
	align-items: center;
	justify-content: center;
	background: none;
	transition: scale .250ms ease-in-out;
}

.button:active {
	transform: scale(0.9);
}

.button > img {
	width: 100%;
	height: 100%;
}
