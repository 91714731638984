/* FONTS */

@font-face {
  font-family: 'AzoSans-Regular';
  src:  url('./assets/resources/fonts/AzoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'AzoSans-Light';
  src:  url('./assets/resources/fonts/AzoSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'AzoSans-Bold';
  src:  url('./assets/resources/fonts/AzoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src:  url('./assets/resources/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src:  url('./assets/resources/fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'SF-UI-Display-Medium';
  src:  url('./assets/resources/fonts/SF-UI-Display-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'SFProDisplay-Regular';
  src:  url('./assets/resources/fonts/SFProDisplay-Regular.ttf') format('truetype');
}
