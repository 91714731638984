/* CSS */
.button{
	background-color: #33FF33;
	border-style: none;
	box-sizing: border-box;
	color: #ffffff;
	cursor: pointer;
	display: inline-block;
	font-weight: 500;
	
	list-style: none;
	margin: 0;
	outline: none;
	position: relative;
	text-align: center;
	text-decoration: none;
	transition: scale 100ms;
	vertical-align: baseline;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
}

.button:hover,
.button:focus {
	transform: scale(0.9);
}
